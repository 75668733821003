import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import ScrollBar from "../components/Scrollbar";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import CreateProduct from "../sections/dashboard/whatsapp-shop/create-product";
import OrderHistory from "../sections/dashboard/whatsapp-shop/order-history";
import OrderMessage from "../sections/dashboard/whatsapp-shop/order-message";
import PaymentGetaway from "../sections/dashboard/whatsapp-shop/payment-getaway";
import ProductList from "../sections/dashboard/whatsapp-shop/product-list";
import ShippingSetting from "../sections/dashboard/whatsapp-shop/shipping-setting";
import VoucherList from "../sections/dashboard/whatsapp-shop/voucher-list";
import AnalyticsSetting from "../sections/dashboard/whatsapp-shop/analytics";

const items = [
  {
    name: "Product List",
    component: <ProductList />,
  },
  {
    name: "Create Product",
    component: <CreateProduct />,
  },
  {
    name: "Order Message",
    component: <OrderMessage />,
  },
  {
    name: "Payment Gateway",
    component: <PaymentGetaway />,
  },
  {
    name: "Discount Setting",
    component: <VoucherList />,
  },
  {
    name: "Shipping Setting",
    component: <ShippingSetting />,
  },
  {
    name: "Analytics Setting",
    component: <AnalyticsSetting />,
  },
  {
    name: "Order History",
    component: <OrderHistory />,
  },
];

export default function WhatsappShop() {
  const [whatsappPages, setWhatsappPages] = React.useState(items[0]);

  return (
    <Page title="Commerce Manager">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Commerce Manager" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {items.map((v, i) => {
                  return (
                    <Button
                      variant={
                        whatsappPages?.name === v.name ? "contained" : "text"
                      }
                      key={i}
                      onClick={() => setWhatsappPages(v)}
                      sx={
                        whatsappPages?.name === v.name
                          ? {
                              background:
                                "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                              borderRadius: "24px",
                              color: "#fdfdfd",
                            }
                          : { color: "#7b7b7b" }
                      }
                    >
                      <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                        {" "}
                        {v.name}
                      </Typography>
                    </Button>
                  );
                })}
              </Stack>
            </ScrollBar>
          </Box>
          <Box mt={3}>{whatsappPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
