import { MenuItem, Stack } from "@mui/material"
import ButtonMenu from "../../../../../components/button/ButtonMenu"

export default function MenuWhatsAppWeb({
    status,
    isFarming,
    login,
    handleLogoutDevice,
    handleGetQrDevice,
    setQrModal,
    handleDeleteFarming,
    handleCreateFarming,
    handleDeleteDevice
}) {
    return (
        <Stack direction="row">
            <ButtonMenu
                sx={{
                    background: 'transparent'
                }}
            >
                {status == "Connected" ?
                    <MenuItem
                        sx={{
                            fontSize: 14
                        }}
                        onClick={handleLogoutDevice}
                    >
                        Disconnect
                    </MenuItem>
                    : status == 'Logged Out' || status == 'Disconnected' ?

                        !login ?
                            <MenuItem
                                sx={{
                                    fontSize: 14
                                }}
                                onClick={() => {
                                    handleGetQrDevice()
                                    setQrModal()
                                }}
                            >
                                Connect
                            </MenuItem>
                            :
                            <MenuItem
                                sx={{
                                    fontSize: 14
                                }}
                                disabled={true}
                            >
                                Connect
                            </MenuItem>
                        : null
                }
                <MenuItem
                    sx={{
                        fontSize: 14
                    }}
                    onClick={() => {
                        if (isFarming) {
                            handleDeleteFarming()
                        } else {
                            handleCreateFarming()
                        }
                    }}
                >
                    {!isFarming ?
                        'Farming' :
                        'Delete Farming'
                    }
                </MenuItem>
                <MenuItem
                    sx={{
                        fontSize: 14,
                        color: '#F15A28'
                    }}
                    onClick={handleDeleteDevice}
                >
                    Delete Account
                </MenuItem>
            </ButtonMenu>
        </Stack>
    )
}