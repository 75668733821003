import { Box, Container } from "@mui/material";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import PlatformOptions from "../components/PlatformOptions";
import { useEffect, useState } from "react";
import WhatsAppWeb from "../sections/dashboard/channels/whatsapp-web";
import CloudAPI from "../sections/dashboard/channels/cloud-api";
import EmailChannel from "../sections/dashboard/channels/email";
import TelegramChannel from "../sections/dashboard/channels/telegram";
import InstagramChannel from "../sections/dashboard/channels/instagram";
import FacebookChannel from "../sections/dashboard/channels/facebook";
import { useDispatch, useSelector } from "react-redux";
import { resetDevice, resetStatusDevice, updateInstagramDevice } from "../store/device/deviceSlice";
import { getDevices } from "../store/device/deviceAction";
import { resetRedirectPath } from "../store/channel/channelSlice";

export default function Channel() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const hash = window.location.hash

    const { redirectPath } = useSelector((state) => state.channel)
    const {updateInstagramDevice: newUpdateInstagramDevice} = useSelector((state)=>state.device)

    const [channelPage, setChannelPage] = useState(
        {
            name: "WhatsApp Web",
            component: <WhatsAppWeb
                loading={loading}
            />,
        }
    )

    const platformOptions = [
        {
            label: "WhatsApp",
            value: "whatsapp",
            image: "whatsapp.png",
        },
        {
            label: "Cloud API",
            value: "waba",
            image: "cloud-api.png",
        },
        {
            label: "Email",
            value: "email",
            image: "email.png",
        },
        {
            label: "Telegram",
            value: "telegram",
            image: "telegram.png",
        },
        {
            label: "Instagram",
            value: "instagram",
            image: "instagram.png",
        },
        {
            label: "Facebook",
            value: "facebook",
            image: "facebook.png",
        },
    ]

    const [platformSelected, setPlatformSelected] = useState({})

    const whatsappItems = [
        {
            name: "WhatsApp Web",
            component: <WhatsAppWeb loading={loading} />,
        },
    ]
    const cloudAPIItems = [
        {
            name: 'Cloud API',
            component: <CloudAPI loading={loading} />
        }
    ]
    const emailItems = [
        {
            name: 'Email',
            component: <EmailChannel loading={loading} />
        }
    ]
    const telegramItems = [
        {
            name: 'Telegram',
            component: <TelegramChannel loading={loading} />
        }
    ]
    const instagramItems = [
        {
            name: 'Instagram',
            component: <InstagramChannel loading={loading} />
        }
    ]
    const facebookItems = [
        {
            name: 'Facebook',
            component: <FacebookChannel loading={loading} />
        }
    ]

    const handleGetDevices = async (platform) => {
        setLoading(true)

        const body = {
            params: {
                platform,
            },
        };
        const result = await dispatch(getDevices(body));
        if (result) {
            setLoading(false)
        }
        dispatch(updateInstagramDevice(false))
    }

    useEffect(()=>{
        if(newUpdateInstagramDevice){
            handleGetDevices('instagram')
        }
    }, [newUpdateInstagramDevice])

    useEffect(() => {
        if (redirectPath == 'whatsapp') {
            setPlatformSelected(platformOptions[0])
        } else if (redirectPath == 'waba') {
            setPlatformSelected(platformOptions[1])
        } else if (redirectPath == 'email') {
            setPlatformSelected(platformOptions[2])
        } else if (redirectPath == 'telegram') {
            setPlatformSelected(platformOptions[3])
        } else if(redirectPath == 'instagram' || hash?.includes('#access_token')){
            setPlatformSelected(platformOptions[4])
        } else if (redirectPath == 'facebook') {
            setPlatformSelected(platformOptions[5])
        }else{
            setPlatformSelected(platformOptions[0])
        }
    }, [redirectPath, hash])

    useEffect(() => {
        if (platformSelected.value == 'whatsapp') {
            setChannelPage(whatsappItems[0])
            handleGetDevices('whatsapp')
        } else if (platformSelected?.value == 'waba') {
            setChannelPage(cloudAPIItems[0])
            handleGetDevices('waba')
        } else if (platformSelected?.value == 'email') {
            setChannelPage(emailItems[0])
            handleGetDevices('email')
        } else if (platformSelected?.value == 'telegram') {
            setChannelPage(telegramItems[0])
            handleGetDevices('telegram')
        } else if (platformSelected?.value == 'instagram') {
            setChannelPage(instagramItems[0])
            handleGetDevices('instagram')
        } else if (platformSelected?.value == 'facebook') {
            setChannelPage(facebookItems[0])
            handleGetDevices('facebook')
        }
    }, [platformSelected])

    useEffect(() => {
        if (channelPage?.name == 'WhatsApp Web') {
            setChannelPage({
                name: 'WhatsApp Web',
                component: <WhatsAppWeb
                    loading={loading}
                />
            })
        } else if (channelPage?.name == 'Cloud API') {
            setChannelPage({
                name: 'Cloud API',
                component: <CloudAPI loading={loading} />
            })
        }  else if (channelPage?.name == 'Instagram') {
            setChannelPage({
                name: 'Instagram',
                component: <InstagramChannel loading={loading} />
            })
        } else if (channelPage?.name == 'Facebook') {
            setChannelPage({
                name: 'Facebook',
                component: <FacebookChannel loading={loading} />
            })
        } else if (channelPage?.name == 'Telegram') {
            setChannelPage({
                name: 'Telegram',
                component: <TelegramChannel loading={loading} />
            })
        } else if (channelPage?.name == 'Email') {
            setChannelPage({
                name: 'Email',
                component: <EmailChannel loading={loading} />
            })
        }
    }, [loading])

    useEffect(() => {
        return () => {
            dispatch(resetRedirectPath())
            dispatch(resetStatusDevice())
        }
    }, [])

    return (
        <Page title="Channel">
            <Container maxWidth="xl">
                <AppDropDown justTitle title="Dashboard / Channel" />
                <PlatformOptions
                    platformOptions={platformOptions}
                    platformSelected={platformSelected}
                    setPlatformSelected={(e) => {
                        dispatch(resetDevice())
                        dispatch(resetStatusDevice())
                        setPlatformSelected(e)
                    }}
                />
                <Box sx={{ width: "100%" }}>
                    <Box mt={3}>{channelPage.component}</Box>
                </Box>
            </Container>
        </Page>
    )
}