import { Box } from "@mui/material";

export default function PlatformOptions({
    platformOptions,
    platformSelected,
    setPlatformSelected
}) {
    return (
        <Box
            sx={{
                mb: 2,
                p: 2,
                display: "flex",
                // justifyContent: { xs: "space-around", md: "start" },
                // alignItems: "center",
                // flexWrap: "wrap",
                // gap: 2,
                background: "rgb(253, 253, 253)",
                borderRadius: 1,
                overflowX: 'auto'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: { xs: "space-around", md: "start" },
                    minWidth: {
                        xs: 400,
                    },
                    width: '100%',
                    alignItems: "center",
                    gap: 2
                }}
            >
                {platformOptions?.map((item, index) => {
                    const isSelected = platformSelected?.value === item?.value;

                    return (
                        <Box
                            key={index}
                            sx={
                                isSelected
                                    ? {
                                        px: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        border: "4px solid rgba(33,194,193,1)",
                                        opacity: "0.6",
                                        background: "#F5F5F5",
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                    }
                                    : {
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }
                            }
                            onClick={() => setPlatformSelected(item)}
                        >
                            <Box
                                component="img"
                                src={`/device/${item?.image}`}
                                sx={{
                                    width: { xs: "60px", md: "80px" },
                                    height: { xs: "60px", md: "80px" },
                                    objectFit: "contain",
                                }}
                            />
                        </Box>
                    );
                })}
            </Box>
        </Box>
    )
}