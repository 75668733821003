import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import {
  deleteCustomerService,
  getCustomerServices,
} from "../../../store/customer-service/customerServiceAction";
import { getCustomerService } from "../../../store/customer-service/customerServiceSlice";
import palette from "../../../theme/palette";
import ButtonTooltip from "../../../components/button/ButtonTooltip";

const tableCell = ["Name", "Username", "Phone", "Email", "Status", "Action"];

export default function CustomerServiceList(props) {
  const dispatch = useDispatch();

  const { customerServices } = useSelector((state) => state.customerService);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const IconList = (data) => {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonTooltip
          title="Edit"
          handleClick={() => {
            dispatch(getCustomerService(data.item));
            props.setEditCustomerService();
          }}
          icon="akar-icons:edit"
        />
        <ButtonTooltip
          title="Delete"
          handleClick={() => {
            handleDeleteCustomerService({
              customer_service_id: `${data.item.id}`,
            });
          }}
          icon="fluent:delete-24-regular"
          color="#F15A28"
        />
      </Stack>
    );
  };

  const handleGetCustomerServices = async (data) => {
    setIsLoading(true);

    await dispatch(getCustomerServices(data));

    setIsLoading(false);
  };

  const handleDeleteCustomerService = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteCustomerService(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        const data = {
          page: page,
        };

        handleGetCustomerServices(data);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    const data = {
      params: {
        page: page,
      },
    };

    handleGetCustomerServices(data);
  }, [page]);

  return (
    <>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: palette.gradients.custom,
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => {
                return (
                  <TableCell align="left" key={index}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography color="#FDFDFD">{item}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : customerServices?.data?.length ? (
              customerServices?.data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{item?.username}</TableCell>
                  <TableCell align="left">{item?.phone}</TableCell>
                  <TableCell align="left">{item?.email}</TableCell>
                  <TableCell align="left">
                    <Box
                      component="button"
                      paddingX={1}
                      paddingY={0.5}
                      width="fit-content"
                      borderRadius="8px"
                      sx={{
                        backgroundColor:
                          item?.status === "1"
                            ? "#c8e6c9"
                            : item?.status === "0"
                              ? " #ffcdd2"
                              : null,
                        border: "none",
                      }}
                    >
                      <Typography color="#131313" fontSize="14px">
                        {item?.status === "1"
                          ? "Active"
                          : item?.status === "0"
                            ? "Non Active"
                            : null}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <IconList item={item} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (parseInt(page) < customerServices?.last_page) {
                setPage(parseInt(page) + 1);
              }
            }}
            disabled={parseInt(page) >= customerServices?.last_page}
          />
        </Box>
      </Box> */}
    </>
  );
}
