import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppDropDown from "../../../sections/dashboard/app/AppDropdown";
import {
  deviceUpdate,
  getDeviceProfile,
  getListCategory,
} from "../../../store/device/deviceAction";
import Page from "../../Page";
import Container from "../../containers/Container";
import BasicInput from "../../input/BasicInput";
import ComboBox from "../../select/ComboBox";
import FormGroup from "../components/FormGroup";

const tempWorkingHours = {
  Monday: ["08:00", "17:00"],
  Tuesday: ["08:00", "17:00"],
  Wednesday: ["08:00", "17:00"],
  Thursday: ["08:00", "17:00"],
  Friday: ["08:00", "17:00"],
  Saturday: ["08:00", "17:00"],
  Sunday: [null, null],
};

export default function DeviceItemUpdate() {
  const { isLoading, data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getListCategory,
  });

  const { isLoading: isLoadingDeviceProfile, data: deviceProfile } = useQuery({
    queryKey: ["device-profile"],
    queryFn: () => getDeviceProfile(device_key),
  });

  const dispatch = useDispatch();
  const { device_key } = useParams();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(deviceProfile?.data?.image);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files?.length === 0) {
      formik.setFieldValue("image", null);
      setSelectedFile(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    formik.setFieldValue("image", e.target.files[0]);
  };

  const formik = useFormik({
    initialValues: {
      device_key: device_key,
      category: {
        name: null,
        display_name: null,
      },
      address: deviceProfile?.data?.address,
      description: deviceProfile?.data?.description,
      email: deviceProfile?.data?.email,
      website: deviceProfile?.data?.website,
      working_hours: deviceProfile?.data?.tempWorkingHours,
      image: null,
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("device_key", device_key);
      formData.append("display_name", values?.display_name);
      formData.append("category", values?.category?.name);
      formData.append("address", values.address);
      formData.append("description", values.description);
      formData.append("email", values.email);
      formData.append("working_hours", JSON.stringify(values.working_hours));
      formData.append("image", values.image); // Append the image file
      formData.append("website", values.website);

      await toast.promise(dispatch(deviceUpdate(formData)), {
        loading: "Please wait...",
        success: (res) => {
          navigate("/dashboard/app");
          return res.payload.message;
        },
        error: (error) => "Error, try again later",
      });
    },
  });

  // Update formik values when deviceProfile changes
  useEffect(() => {
    if (deviceProfile && categories) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        display_name: deviceProfile.data.display_name,
        category: categories?.find(
          (e) => e.name === deviceProfile?.data.category
        ),
        address: deviceProfile.data.address,
        description: deviceProfile.data.description,
        email: deviceProfile.data.email,
        website: deviceProfile.data.website,
        working_hours: deviceProfile.data.working_hours,
        // Update other fields accordingly
      }));
      setPreview(deviceProfile.data.image);
    }
  }, [deviceProfile, categories]);

  const handleChangeWorkingHours = useCallback((day, timeType, value) => {
    let current_working_hours = []
    current_working_hours = formik.values.working_hours

    const getDay = current_working_hours.find(item =>
      item?.name === day
    )
    const currentIndexDay = current_working_hours.findIndex(item =>
      item?.name === day
    )
    if (!getDay) {
      toast.dismiss()
      toast.error('The day is not specified exactly')
      return
    }
    // update working_hours of current day (open_time || close_time)
    if (timeType === 'open_time') {
      current_working_hours[currentIndexDay] = {
        ...current_working_hours[currentIndexDay],
        open_time: value
      }
    } else if (timeType === 'close_time') {
      current_working_hours[currentIndexDay] = {
        ...current_working_hours[currentIndexDay],
        close_time: value
      }
    }
    formik.setFieldValue('working_hours', current_working_hours)
  }, [formik.values])

  return (
    <Page title="Update Profile">
      <Container maxWidth="xl">
        <AppDropDown title="Account / Profile" />
        {isLoadingDeviceProfile && (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography>Loading</Typography>
          </Box>
        )}
        {!isLoadingDeviceProfile && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box marginBottom={"40px"}>
                <Typography
                  fontSize={{ xs: 20, sm: 24 }}
                  fontWeight="700"
                  color="#174547"
                >
                  Profile
                </Typography>
              </Box>
              <FormGroup
                title="Profile Photo"
                subTitle={"This will be visible on your business profile"}
              >
                <Box position="relative" height={96} width={96}>
                  <img
                    src={
                      selectedFile || preview
                        ? preview
                        : "/images/komers-logo.svg"
                    }
                    style={{
                      width: "96px",
                      height: "96px",
                      borderRadius: "50%",
                    }}
                  />
                  <input
                    id="photo"
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={onSelectFile}
                  />
                  <label htmlFor="photo">
                    <Box
                      width={32}
                      sx={{
                        cursor: "pointer",
                      }}
                      position={"absolute"}
                      bottom={0}
                      right={0}
                      component={"img"}
                      src="/icons/form/pencil.svg"
                      alt="pencil"
                    />
                  </label>
                </Box>
              </FormGroup>
              <FormGroup title="Display name">
                <BasicInput
                  fullWidth
                  disableMargin
                  placeholder="Display Name"
                  value={formik.values?.display_name}
                  onChange={(e) => {
                    formik.setFieldValue("display_name", e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup title="Category">
                <ComboBox
                  default
                  fullWidth
                  disableMargin
                  placeholder="Select category"
                  options={categories ?? []}
                  getOptionLabel={(option) => option?.display_name}
                  value={formik.values.category}
                  onChange={(e, value) => {
                    formik.setFieldValue("category", value);
                  }}
                />
              </FormGroup>

              <FormGroup
                title="Description"
                subTitle={"Tell your customers about your company"}
              >
                <BasicInput
                  fullWidth
                  multiline
                  rows={3}
                  disableMargin
                  placeholder="Description"
                  {...formik.getFieldProps("description")}
                />
              </FormGroup>
              <Box marginBottom={"40px"}>
                <Typography
                  fontSize={{ xs: 20, sm: 24 }}
                  fontWeight="700"
                  color="#174547"
                >
                  Contact Information
                </Typography>
              </Box>
              <FormGroup title="Address">
                <BasicInput
                  fullWidth
                  disableMargin
                  placeholder="Address"
                  {...formik.getFieldProps("address")}
                />
              </FormGroup>
              <FormGroup title="Email">
                <BasicInput
                  fullWidth
                  disableMargin
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
              </FormGroup>
              <FormGroup title="Website">
                <BasicInput
                  fullWidth
                  disableMargin
                  placeholder="Website"
                  {...formik.getFieldProps("website")}
                />
              </FormGroup>
              <FormGroup title="Working Hours">
                <Stack spacing={2}>
                  {Object.keys(tempWorkingHours).map((day, index) => (
                    <Stack spacing={1} key={index}>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        color={"#7B7B7B"}
                      >
                        {day}
                      </Typography>
                      <Stack direction={"row"} spacing={2}>
                        <BasicInput
                          type="time"
                          fullWidth
                          disableMargin
                          placeholder=""
                          onChange={(e) => handleChangeWorkingHours(day, 'open_time', e.target.value)}
                          value={formik.values[`working_hours`]?.[index].open_time}
                        // {...formik.getFieldProps(`working_hours.${day}[0]`)}
                        />
                        <BasicInput
                          type="time"
                          fullWidth
                          disableMargin
                          placeholder=""
                          onChange={(e) => handleChangeWorkingHours(day, 'close_time', e.target.value)}
                          value={formik.values[`working_hours`]?.[index].close_time}
                        // {...formik.getFieldProps(`working_hours.${day}[1]`)}
                        />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                padding={"20px"}
                borderRadius={2}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <Box marginBottom={"40px"}>
                  <Typography
                    fontSize={{ xs: 20, sm: 24 }}
                    fontWeight="700"
                    color="#174547"
                  >
                    Business profile review
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box padding={"40px"}>
                    <Box
                      width={"158px"}
                      height="158px"
                      component={"img"}
                      src={
                        selectedFile || preview
                          ? preview
                          : "/images/komers-logo.svg"
                      }
                    />
                  </Box>
                  <Typography
                    color={"#174547"}
                    fontWeight={"600"}
                    textAlign={"center"}
                    fontSize={"24px"}
                    marginBottom={"0px"}
                  >
                    komers.io
                  </Typography>
                  <Typography
                    color={"#7B7B7B"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    lineHeight={"24px"}
                  >
                    Business account
                  </Typography>
                </Box>

                <Stack direction={"column"} marginTop="40px" gap={2}>
                  <Stack direction={"row"} alignItems={"start"} gap={1}>
                    <Box
                      width={"20px"}
                      height="20px"
                      marginTop={"2px"}
                      component={"img"}
                      src="/icons/form/shop.svg"
                    />
                    <Typography
                      color={"#7B7B7B"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"24px"}
                    >
                      {formik.values.address}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"start"} gap={1}>
                    <Box
                      width={"20px"}
                      height="20px"
                      marginTop={"2px"}
                      component={"img"}
                      src="/icons/form/tag.svg"
                    />
                    <Typography
                      color={"#7B7B7B"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"24px"}
                    >
                      {formik.values.display_name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"start"} gap={1}>
                    <Box
                      width={"20px"}
                      height="20px"
                      marginTop={"2px"}
                      component={"img"}
                      src="/icons/form/location.svg"
                    />
                    <Typography
                      color={"#7B7B7B"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"24px"}
                    >
                      {formik.values.address}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"start"} gap={1}>
                    <Box
                      width={"20px"}
                      height="20px"
                      marginTop={"2px"}
                      component={"img"}
                      src="/icons/form/email.svg"
                    />
                    <Typography
                      color={"#7B7B7B"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"24px"}
                    >
                      {formik.values.email}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"start"} gap={1}>
                    <Box
                      width={"20px"}
                      height="20px"
                      marginTop={"2px"}
                      component={"img"}
                      src="/icons/form/web.svg"
                    />
                    <Typography
                      color={"#7B7B7B"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"24px"}
                    >
                      {formik.values.website}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item sx={12}>
              <FormGroup
                title="Official business account"
                subTitle={
                  <div>
                    An official business account has a green tick{" "}
                    <img
                      src="/images/verified-badge.svg"
                      alt="Custom Image"
                      style={{
                        display: "inline",
                      }}
                    />{" "}
                    next to its name. This shows that WhatsApp has confirmed
                    that an authentic and notable brand owns this account. Learn
                    more.
                  </div>
                }
              >
                <Button
                  sx={{
                    backgroundColor: "#17454780",
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: "16px",
                    padding: "14px 24px",
                    "&:hover": {
                      backgroundColor: "#174547",
                    },
                  }}
                  onClick={formik.handleSubmit}
                >
                  Submit Request
                </Button>
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
