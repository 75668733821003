import Box from "@mui/material/Box";
import * as React from "react";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        background:
          "linear-gradient(95deg, rgba(147, 223, 147, 0.20) 0%, rgba(33, 194, 193, 0.20) 100%), #FFF;",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundImage: "url('/auth/illust-bg.svg')",
          backgroundSize: "",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            padding: "24px 8px",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
