import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const {isHoverSidebar} = useSelector((state) => state.template)

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR

  const logo = (
    <Box
      sx={{
        width: 200,
        height: 52,
        ...sx,
        display: "contents",
        justifyContent: "center",
        
      }}
    >
      {/* {
        isHoverSidebar && (
          <img src="/images/komers.svg"  style={{ width: "150px" }} alt="Komers" />
        )
      }
      {
        !isHoverSidebar && (
          <img src="/images/komers.svg"  style={{ width: "150px" }} alt="Komers" />
        )
      } */}
      
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
