import { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import { useNavigate, useParams } from "react-router-dom";
import ConnectWhatsAppWeb from "../sections/dashboard/channels/connect-channel/whatsapp-web";
import ConnectCloudAPI from "../sections/dashboard/channels/connect-channel/cloud-api";
import ConnectTelegram from "../sections/dashboard/channels/connect-channel/telegram";
import ConnectInstagram from "../sections/dashboard/channels/connect-channel/instagram";
import ConnectEmail from "../sections/dashboard/channels/connect-channel/email";
import ConnectFacebook from "../sections/dashboard/channels/connect-channel/facebook";
import { useDispatch } from "react-redux";
import { setRedirectPath } from "../store/channel/channelSlice";

export default function ConnectChannel() {
    const { connect_channel } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [connectPage, setConnectPage] = useState({
        name: "WhatsApp Web",
        breadcrumbs: 'Connect WhatsApp Web',
        component: <ConnectWhatsAppWeb />,
    })

    function routeValidation() {
        if (
            connect_channel == 'connect-whatsapp-web' ||
            connect_channel == 'connect-cloud-api' ||
            connect_channel == 'connect-email' ||
            connect_channel == 'connect-telegram' ||
            connect_channel == 'connect-instagram' ||
            connect_channel == 'connect-facebook'
        ) {
            return
        }
        navigate('/404')
    }

    useEffect(() => {
        routeValidation()

        if (connect_channel == 'connect-whatsapp-web') {
            setConnectPage({
                name: "WhatsApp Web",
                breadcrumbs: 'Connect WhatsApp Web',
                component: <ConnectWhatsAppWeb />,
            })
            dispatch(setRedirectPath('whatsapp'))
        } else if (connect_channel == 'connect-cloud-api') {
            setConnectPage({
                name: "Cloud API",
                breadcrumbs: 'Connect Cloud API',
                component: <ConnectCloudAPI />,
            })
            dispatch(setRedirectPath('waba'))
        }  else if (connect_channel == 'connect-instagram') {
            setConnectPage({
                name: "Instagram",
                breadcrumbs: 'Connect Instagram',
                component: <ConnectInstagram />,
            })
            dispatch(setRedirectPath('instagram'))
        } else if (connect_channel == 'connect-facebook') {
            setConnectPage({
                name: "Facebook",
                breadcrumbs: 'Connect Facebook',
                component: <ConnectFacebook />,
            })
            dispatch(setRedirectPath('waba'))
        }  else if (connect_channel === 'connect-email') {
            setConnectPage({
                name: "Email",
                breadcrumbs: 'Connect Email',
                component: <ConnectEmail />,
            })
            dispatch(setRedirectPath('email'))
        } else if (connect_channel == 'connect-telegram') {
            setConnectPage({
                name: "Telegram",
                breadcrumbs: 'Connect Telegram',
                component: <ConnectTelegram />,
            })
            dispatch(setRedirectPath('telegram'))
        }
    }, [])
    return (
        <Page title="Connect Channel">
            <Container maxWidth="xl">
                <AppDropDown justTitle title={`Dashboard / Channel / ${connectPage.breadcrumbs}`} />
                <Box sx={{ width: "100%" }}>
                    <Box mt={3}>{connectPage.component}</Box>
                </Box>
            </Container>
        </Page>
    )
}