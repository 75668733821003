import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import TeamInbox from "../sections/dashboard/chat-team/TeamInbox";
import LogActivity from "../sections/dashboard/chat-team/LogActivity";

export default function TeamInboxPage() {
  const [pages, setPages] = React.useState({
    name: "Team Inbox",
    component: <TeamInbox />,
  });

  const items = [
    {
      name: "KomChat",
      component: (
        <iframe
          src="https://team.realm.chat"
          width="100%"
          height="800px"
          frameBorder="0"
          title="KomChat"
        ></iframe>
      ),
    },
    // {
    //   name: "TikTok",
    //   component: <TikTok />,
    // },
  ];

  return (
    <Page title="Team Inbox">
      <Container maxWidth="xl"> 
        {/* <AppDropDown justTitle title="Dashboard / Team Inbox" /> */}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <Stack direction="row" spacing={1}>
              {items.map((v, i) => {
                return (
                  <Button
                    variant={pages.name === v.name ? "contained" : "text"}
                    key={i}
                    onClick={() => setPages(v)}
                    sx={
                      pages?.name === v.name
                        ? {
                            background:
                              "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                        : { color: "#7b7b7b" }
                    }
                  >
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                    {" "}
                    {v.name}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
          </Box>
          <Box sx={{ width: "100%", paddingTop: '-10px' }}>{pages.component}</Box> {/* Add margin-top for extra space */}
        </Box>
      </Container>
    </Page>
  );  
}
