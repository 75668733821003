import { Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import Container from "../../../components/containers/Container";
import { getIncomeStatistic } from "../../../store/user/userAction";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function AppIncomeStatistic() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  const [IncomeStatisticValue, setIncomeStatisticValue] = useState(null);

  const handleGetIncomeStatistic = async () => {
    await dispatch(getIncomeStatistic({})).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return;
      }

      setIncomeStatisticValue(res.payload.data);
    });
  };

  useEffect(() => {
    handleGetIncomeStatistic();
  }, []);

  //console.log(IncomeStatisticValue?.chart[0]?.Orders);

  const state = {
    series: [
      {
        name: "WhatsApp",
        data: [IncomeStatisticValue?.chart[0]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[1]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[2]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[3]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[4]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[5]?.Orders ?? 0, 
        IncomeStatisticValue?.chart[6]?.Orders ?? 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },

      grid: {
        row: {
          colors: ["#FDFDFD", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          IncomeStatisticValue?.chart[0]?.period ?? "",
          IncomeStatisticValue?.chart[1]?.period ?? "",
          IncomeStatisticValue?.chart[2]?.period ?? "",
          IncomeStatisticValue?.chart[3]?.period ?? "",
          IncomeStatisticValue?.chart[4]?.period ?? "",
          IncomeStatisticValue?.chart[5]?.period ?? "",
          IncomeStatisticValue?.chart[6]?.period ?? "",
        ],
      },
    },
  };

  return (
    <Container title="Income Statistic">
      <ReactApexChart
        options={state?.options}
        series={state?.series}
        type="line"
        height={350}
      />
      <Box display="flex" alignItems="center" justifyContent="flex-start" p={1}>
        <Box ml={3}>
          <Typography color="#373d3f" fontWeight="600">
            This Week's Income is {" "}
            <span style={{ fontWeight: "600", color: "#0D4AFD" }}>{IncomeStatisticValue?.total_sales}</span>
          </Typography>
          <Typography fontSize="12px">
            Today's income is 0 % from yesterday
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
