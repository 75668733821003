import { Box } from "@mui/material";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getImage = (name, type ) => (
  <Box
    component="img"
    sx={{
      height: 22,
      width: 22,
    }}
    alt={name}
    src={`/icons/sidebar/${name}`}
  />
);
const getIcon = (name) => <Iconify  icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "home",
    path: "/dashboard/app",
    icon: getImage("/outline/Home.svg"),
    // icon: getIcon("eva:home-outline"),
    url: false,
  },
  {
    title: "Channels",
    path: '/dashboard/channels',
    icon: getImage("/outline/Channel.svg"),
    url: false
  },
  {
    title: "Contacts",
    path: "/dashboard/contacts",
    icon: getImage("/outline/Contact.svg"),
    // icon: getIcon("cil:contact"),
    url: false,
  },
  {
    title: "Commerce Manager",
    path: "/dashboard/whatsapp-shop",
    icon: getImage("/outline/Commerce Manager.svg"),
    // icon: getIcon("fluent:building-shop-20-regular"),
    url: false,
  },
  {
    title: "Message Templates",
    path: "/dashboard/template-message",
    icon: getImage("/outline/Template.svg"),
    // icon: getIcon("carbon:template"),
    url: false,
  },
  {
    title: "marketing",
    path: "/dashboard/marketings",
    icon: getImage("/outline/Marketing.svg"),
    // icon: getIcon("nimbus:marketing"),
    url: false,
  },
  {
    title: "Customer Service",
    path: "/dashboard/customer-service",
    icon: getImage("/outline/Team Inbox.svg"),
    // icon: getIcon("ic:sharp-support-agent"),
    url: false,
  },
  {
    title: "Team Inbox",
    path: "/dashboard/team-inbox",
    icon: getImage("/outline/Chat.png"),
    // icon: getIcon("ic:sharp-support-agent"),
    url: false,
  },
  {
    title: "integration",
    path: "/dashboard/integration",
    icon: getImage("/outline/Integration.svg"),
    // icon: getIcon("uil:server-network-alt"),
    url: false,
  },
  {
    title: "report",
    path: "/dashboard/report",
    icon: getImage("/outline/Report.svg"),
    // icon: getIcon("iconoir:stats-report"),
    url: false,
  },
  {
    title: "cloud storage",
    path: "/dashboard/cloud-storage",
    icon: getImage("/outline/Cloud Storage.svg"),
    // icon: getIcon("material-symbols:cloud-upload"),
    url: false,
  },
];

const navConfigFill = [
  {
    title: "home",
    path: "/dashboard/app",
    icon: getImage("/fill/Home.svg"),
    // icon: getIcon("eva:home-outline"),
    url: false,
  },
  {
    title: "Channels",
    path: '/dashboard/channels',
    icon: getImage("/fill/Channel.svg"),
    url: false
  },
  {
    title: "Contacts",
    path: "/dashboard/contacts",
    icon: getImage("/fill/Contact.svg"),
    // icon: getIcon("cil:contact"),
    url: false,
  },
  {
    title: "Commerce Manager",
    path: "/dashboard/whatsapp-shop",
    icon: getImage("/fill/Commerce Manager.svg"),
    // icon: getIcon("fluent:building-shop-20-regular"),
    url: false,
  },
  {
    title: "Message Templates",
    path: "/dashboard/template-message",
    icon: getImage("/fill/Template.svg"),
    // icon: getIcon("carbon:template"),
    url: false,
  },
  {
    title: "marketing",
    path: "/dashboard/marketings",
    icon: getImage("/fill/Marketing.svg"),
    // icon: getIcon("nimbus:marketing"),
    url: false,
  },
  {
    title: "Customer Service",
    path: "/dashboard/customer-service",
    icon: getImage("/fill/Team Inbox.svg"),
    // icon: getIcon("ic:sharp-support-agent"),
    url: false,
  },
  {
    title: "Team Inbox",
    path: "/dashboard/team-inbox",
    icon: getImage("/fill/Chat.png"),
    // icon: getIcon("ic:sharp-support-agent"),
    url: false,
  },
  {
    title: "integration",
    path: "/dashboard/integration",
    icon: getImage("/fill/Integration.svg"),
    // icon: getIcon("uil:server-network-alt"),
    url: false,
  },
  {
    title: "report",
    path: "/dashboard/report",
    icon: getImage("/fill/Report.svg"),
    // icon: getIcon("iconoir:stats-report"),
    url: false,
  },
  {
    title: "cloud storage",
    path: "/dashboard/cloud-storage",
    icon: getImage("/fill/Cloud Storage.svg"),
    // icon: getIcon("material-symbols:cloud-upload"),
    url: false,
  },
];


export default navConfig;
