import React from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Menu,
  Badge,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../components/button/BasicButton";
import Notification from "./Notification";

// const DRAWER_WIDTH = 280;
const DRAWER_WIDTH = 70;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

const menus = [
  // {
  //   name: "united kingdom",
  //   code: "UK",
  //   value: 1,
  //   icon: "/icons/flag/uk.png",
  // },
  {
    name: "malaysia",
    code: "MYS",
    value: 2,
    icon: "/icons/flag/malaysia.png",
  },
  // {
  //   name: "indonesia",
  //   code: "IDN",
  //   value: 3,
  //   icon: "/icons/flag/idn.png",
  // },
];

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();

  const [lang, setLang] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setLang(event.target.value);
  };


  const { user, plan } = useSelector((state) => state.user);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box
          bgcolor="#FDFDFD"
          p="13px"
          borderRadius={1}
          display="flex"
          width="100%"
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 10 }}
          >
            <img src="/images/komers.svg" alt="avatar" height={36} style={{ marginRight: 50 }} />
            {/* <Box
              src="/images/hasan.png"
              component="img"
              display={{ xs: "none", sm: "flex" }}
            />  */}
            {/* <Typography
            color="#7B7B7B"
            fontWeight="600"
            display={{ xs: "none", sm: "flex" }}
          > Plan:  {" "}
             {plan?.name}
          </Typography>
          <BasicButton
              title="Manage Subscription"
              onClick={() => navigate("/dashboard/plan")}
            />
          <Typography
            color="#7B7B7B"
            fontWeight="600"
            display={{ xs: "none", sm: "flex" }}
          >
             Expired:  {" "}
            {plan?.expired_at}
          </Typography>
          </Stack>
          <Box sx={{ flexGrow: 0.5 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 10 }}
          >
            <Typography
            color="#7B7B7B"
            fontWeight="600"
            display={{ xs: "none", sm: "flex" }}
          >
             Device: {" "}
             {plan?.total_device} / {plan?.device}
          </Typography>
          <Typography
            color="#7B7B7B"
            fontWeight="600"
            display={{ xs: "none", sm: "flex" }}
          >
             Users: {" "}
             {plan?.total_cs} / {plan?.customer_service}
          </Typography> */}
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 2 }}
          >
          <BasicButton
              title="Manage Subscription"
              onClick={() => navigate("/dashboard/plan")}
            />
             {/* <Typography
                  color="#7B7B7B"
                  fontWeight="600"
                  display={{ xs: "none", sm: "flex" }}
                >
             Credits:{" "}
              {user?.currency} {parseFloat(user?.wallet).toFixed(2)}
                </Typography> */}
            {/* <Tooltip title="Notification">
              <Link
                onClick={handleClick}
                underline="none"
                sx={{ cursor: "pointer", display: "flex", gap: 1, mr: 0.5 }}
              >
                <Badge badgeContent={0} color="default">
                  <Iconify
                    icon="bx:bell"
                    width={22}
                    height={22}
                    color="#7B7B7B"
                  />
                </Badge>
                <Typography
                  color="#7B7B7B"
                  fontWeight="600"
                  display={{ xs: "none", sm: "flex" }}
                >
                  Notification
                </Typography>
              </Link>
            </Tooltip> */}
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Notification />
              </MenuItem>
            </Menu>
            {/* <Box sx={{ minWidth: 84 }}>
              <FormControl fullWidth variant="standard">
                <Select
                  disableUnderline
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  label="Age"
                  onChange={handleChange}
                >
                  {menus.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        <Box display="flex" alignItems="center">
                          <Box
                            component="img"
                            src={item.icon}
                            sx={{ width: 20, height: 14, mr: 1 }}
                          />
                          <Typography color="#7B7B7B">{item.code}</Typography>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box> */}
          </Stack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
