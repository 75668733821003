import {
  Box,
  Button,
  Card,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import EmptyBox from "../../../../components/EmptyBox";
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import FormContainer from "../../../../components/containers/FormContainer";
import CustomModal from "../../../../components/modal/CustomModal";
import {
  deleteContact,
  exportContactToExcel,
  getContacts,
  getProfilePictureContact,
} from "../../../../store/contact/contactAction";
import { getDevices } from "../../../../store/device/deviceAction";
import palette from "../../../../theme/palette";
import {
  IconList,
  IconListResponsive,
  addOption,
  tableCell,
  tableCellMobile,
} from "./Config";
import DetailContact from "./DetailContact";
import FormAddContact from "./FormAddContact";
import FormDevice from "./FormDevice";
import FormFile from "./FormFile";
import FormWhatsappGroup from "./FormWhatsappGroup";
import Tags from "./Tags";

export default function ContactList() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { contacts, pictureContacts } = useSelector((state) => state.contact);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [modalItems, setModalItems] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [personToEdit, setPersonToEdit] = useState(null)

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const open = Boolean(anchorEl);

  const _onAddContact = (v) => {
    if (v.id === 1) {
      setModalItems(<FormAddContact onCancel={() => setOpenModal(false)} />);
    } else if (v.id === 2) {
      setModalItems(<FormFile onCancel={() => setOpenModal(false)} />);
    } else if (v.id === 3) {
      setModalItems(<FormDevice onCancel={() => setOpenModal(false)} />);
    } else {
      setModalItems(<FormWhatsappGroup onCancel={() => setOpenModal(false)} />);
    }
    setAnchorEl(null);
    setOpenModal(!openModal);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (v) => {
    setAnchorEl(null);
  };

  const addContactButtons = [
    {
      title: "Export Excel",
      icon: null,
      prev: () => handleExportContactToExcel(),
    },
    {
      title: "Delete All",
      icon: null,
      prev: () => {
        setIsOpenModalDelete(!isOpenModalDelete);
      },
    },
    {
      title: "Add Contact",
      icon: "ic:baseline-arrow-drop-down",
      prev: handleOpen,
    },
  ];

  const handleExportContactToExcel = async () => {
    toast.loading("Please wait...");

    await dispatch(exportContactToExcel()).then((res) => {
      toast.dismiss();
      toast.success("Export Contact Successfully ...");
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Komers Contact.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const handleGetContacts = async (data) => {
    setIsLoading(true);

    await dispatch(getContacts(data));

    setIsLoading(false);
  };

  const handleDeleteContact = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteContact(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        const data = {
          params: {
            search: search,
          },
        };

        handleGetContacts(data);
        setIsOpenModalDelete(false);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  const handleGetProfilePictureContact = async (data) => {
    await dispatch(getProfilePictureContact(data));
  };

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba",
      },
    };

    await dispatch(getDevices());
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    const data = {
      params: {
        search: search,
        page: page,
      },
    };

    handleGetContacts(data);
  }, [search, page]);

  useEffect(() => {
    if (contacts && devices.length) {
      const contactsRaw = [];
      const devicesRaw = [];

      contacts?.data?.map((item) => {
        contactsRaw.push(item.phone);
      });

      devices?.map((item) => {
        devicesRaw.push(item.device_key);
      });

      const data = {
        accountid: devicesRaw,
        numbers: contactsRaw,
      };

      handleGetProfilePictureContact(data);
    }
  }, [devices, contacts]);

  return (
    <>
      <Card
        style={{
          backgroundColor: "#FDFDFD",
          padding: "16px",
          borderRadius: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={1}
          pl={2}
          pr={1}
          alignItems="center"
        >
          <Box>
            <Typography
              fontSize={{ xs: 20, sm: 24 }}
              fontWeight="700"
              color="#131313"
            >
              Contact List
            </Typography>
          </Box>
          <IconListResponsive
            onClickAddIcon={handleOpen}
            onClickDeleteIcon={() => setIsOpenModalDelete(!isOpenModalDelete)}
            onClickTagIcon={() => setOpenTagsModal(true)}
            onClickExportIcon={() => handleExportContactToExcel()}
          />
          <Stack
            spacing={1}
            display={{ xs: "none", sm: "flex", md: "flex" }}
            direction="row"
          >
            {addContactButtons.map((item, index) => {
              return (
                <Box display="flex" alignItems="center" key={index}>
                  <ButtonWithIcon
                    title={item.title}
                    icon={item.icon}
                    onClick={item.prev}
                  />
                </Box>
              );
            })}
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {addOption.map((item, index) => {
                  return (
                    <MenuItem key={index} onClick={() => _onAddContact(item)}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </Stack>
        </Box>
        <FormControl
          fullWidth
          sx={{ marginTop: 2, marginBottom: 2 }}
          onChange={(e) => setSearch(e.target.value)}
        >
          <OutlinedInput
            style={{ height: "40px" }}
            id="outlined-adornment-amount"
            placeholder="Search contact"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon={"heroicons:magnifying-glass"}
                  color="#7B7B7B"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />
        </FormControl>
        {contacts ? (
          <Scrollbar>
            <TableContainer
              component={Paper}
              style={{ backgroundColor: "#FDFDFD" }}
            >
              <Table
                aria-label="simple table"
                sx={{ display: { xs: "none", md: "table" } }}
              >
                <TableHead
                  style={{
                    background: palette.gradients.custom,
                  }}
                >
                  <TableRow>
                    {tableCell.map((item, index) => {
                      return (
                        <TableCell align="left" key={index}>
                          <Box
                            alignItems="center"
                            display="flex"
                            justifyContent={"space-between"}
                          >
                            <Typography color="#FDFDFD">{item}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Box
                          component="img"
                          src="/images/loading.svg"
                          alt="loading"
                          height="48px"
                          sx={{ mx: "auto" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : contacts?.data?.length ? (
                    contacts?.data?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {page === 1
                            ? index + 1
                            : page * contacts?.pagination?.per_page - 9 + index}
                        </TableCell>
                        {/* <TableCell align="left">
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              {(pictureContacts ?? [])?.find(
                                (v) => v.number?.split("@")[0] === item?.phone
                              )?.url ? (
                                <Box>
                                  <Box
                                    component="img"
                                    height="40px"
                                    width="40px"
                                    src={
                                      (pictureContacts ?? [])?.find(
                                        (v) =>
                                          v.number?.split("@")[0] ===
                                          item?.phone
                                      )?.url
                                    }
                                    sx={{
                                      borderRadius: "50%",
                                      maxWidth: "auto",
                                    }}
                                    alt="logo"
                                  />
                                </Box>
                              ) : (
                                <Iconify
                                  icon="mdi:user-circle"
                                  height="40px"
                                  width="40px"
                                  color="#94a3b8"
                                />
                              )}
                            </Box>
                          </Box>
                        </TableCell> */}
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.keyword}</TableCell>
                        <TableCell align="left">{item.phone}</TableCell>
                        <TableCell align="left">{item.email}</TableCell>
                        {/* <TableCell align="left">{item.company_name}</TableCell>
                        <TableCell align="left">{item.job_title}</TableCell>
                        <TableCell align="left">{item.lead_stage}</TableCell>
                        <TableCell align="left">{item.country}</TableCell>
                        <TableCell align="left">{item.optin}</TableCell>
                        <TableCell align="left">{item.channel}</TableCell>
                        <TableCell align="left">{item.last_you_contact}</TableCell>
                        <TableCell align="left">{item.last_customer_contact}</TableCell>
                        <TableCell align="left">{item.created_at}</TableCell>
                        <TableCell align="left">{item.updated_at}</TableCell> */}
                        <TableCell align="left">
                          <IconList
                            onEdit={() =>{
                              setPersonToEdit(item)
                              setOpenDetail(true)
                            }}
                            onDelete={() =>
                              handleDeleteContact({
                                phone: item.phone,
                              })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        Data Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Table
                aria-label="simple table"
                sx={{ display: { xs: "table", md: "none" } }}
              >
                <TableHead
                  style={{
                    background: palette.gradients.custom,
                  }}
                >
                  <TableRow>
                    {tableCellMobile.map((item, index) => {
                      return (
                        <TableCell align="left" key={index}>
                          <Box
                            alignItems="center"
                            display="flex"
                            justifyContent={"space-between"}
                          >
                            <Typography color="#FDFDFD">{item}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Box
                          component="img"
                          src="/images/loading.svg"
                          alt="loading"
                          height="48px"
                          sx={{ mx: "auto" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : contacts?.data?.length ? (
                    contacts?.data?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <Box sx={{ display: "flex" }}>
                            {/* <Box>
                              {(pictureContacts ?? [])?.find(
                                (v) => v.number?.split("@")[0] === item?.phone
                              )?.url ? (
                                <Box>
                                  <Box
                                    component="img"
                                    height="40px"
                                    width="40px"
                                    src={
                                      (pictureContacts ?? [])?.find(
                                        (v) =>
                                          v.number?.split("@")[0] ===
                                          item?.phone
                                      )?.url
                                    }
                                    sx={{
                                      borderRadius: "50%",
                                      maxWidth: "auto",
                                    }}
                                    alt="logo"
                                  />
                                </Box>
                              ) : (
                                <Iconify
                                  icon="mdi:user-circle"
                                  height="40px"
                                  width="40px"
                                  color="#94a3b8"
                                />
                              )}
                            </Box> */}
                            <Box sx={{ ml: 1 }}>
                              <Typography fontSize={14} fontWeight="600">
                                {item.name}
                              </Typography>
                              <Typography fontSize={14}>
                                {item.phone}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <IconList
                            onEdit={() => {
                              setOpenDetail(true)
                              setPersonToEdit(item)
                            }}
                            onDelete={() =>
                              handleDeleteContact({ phone: item.phone })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        Data Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        ) : (
          <EmptyBox
            title1="No Contact Database"
            title2="Generate now"
            link="Contact Scrapping"
          />
        )}

        <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title="<"
              onClick={() => {
                if (parseInt(page) > 1) {
                  setPage(parseInt(page) - 1);
                }
              }}
              disabled={parseInt(page) === 1}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title=">"
              onClick={() => {
                if (parseInt(page) < contacts?.pagination?.last_page) {
                  setPage(parseInt(page) + 1);
                }
              }}
              disabled={parseInt(page) >= contacts?.pagination?.last_page}
            />
          </Box>
        </Box>
      </Card>

      {/* Add Contacts Modal */}
      <CustomModal open={openModal}>{modalItems}</CustomModal>
      <CustomModal open={isOpenModalDelete}>
        <FormContainer title="Area you sure delete all contact?">
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button onClick={() => setIsOpenModalDelete(!isOpenModalDelete)}>
              Cancel
            </Button>
            <BasicButton
              title="Delete"
              type="submit"
              onClick={() => handleDeleteContact({ phone: "all" })}
            />
          </Stack>
        </FormContainer>
      </CustomModal>

      {/* Tags Modal */}
      <CustomModal
        handleClose={() => setOpenTagsModal(false)}
        open={openTagsModal}
      >
        <Tags />
      </CustomModal>

      {/* Details Contact Modal */}
      <CustomModal open={openDetail}>
        <DetailContact
        page={page}
        search={search}
        personToEdit={personToEdit}
        onClose={() => {
          setOpenDetail(false)
          setPersonToEdit(null)
        }}
        />
      </CustomModal>
    </>
  );
}
