import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { addStatusDevice, deleteStatusDevice, setDeviceKey, toggleModalInstagram, toggleModalTelegram, toggleModalWaba, updateStatusDevice } from "../../../../store/device/deviceSlice";
import { deleteDevice, getDevices, getListDevicesForCombobox, getStatusDevice, getStatusDeviceClient, logoutDevice, unlinkDevice } from "../../../../store/device/deviceAction";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../../../services/socket";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { createFarming, deleteFarming } from "../../../../store/farming/farmingAction";
import { updateCatalogSetting } from "../../../../store/catalog/catalogAction";
import DesktopMode from "./DesktopMode";
import MobileMode from "./MobileMode";

export default function DeviceChannel({
    icon,
    item,
    platform,
    handleGetQrDevice,
    setQrModal,
    setQrKey,
    setDeviceKey: setStateDeviceKey,
    deviceKey,
    // style on mobile mode
    borderRadius,
    borderBottom,
    padding = 2,
    paddingSummary,
    paddingDetails,
    // style on desktop mode
    borderRadiusDesktop = 1,
    paddingDesktop = 2,
    borderBottomDesktop
}) {
    const dispatch = useDispatch()
    const { statusDevices } = useSelector((state) => state.device)

    const [login, setLogin] = useState(false)
    const [status, setStatus] = useState('Fetching Status')
    const [isFarming, setIsFarming] = useState(false)
    const [wabaStatus, setWabaStatus] = useState(null)
    const navigate = useNavigate()

    async function loadGetStatusDevice() {
        let statusRaw = "Fetching Status"
        let isFarming = false

        const response = await dispatch(
            item?.platform == 'whatsapp' || item?.platform == 'waba' ?
                getStatusDevice({
                    accountid: item.device_key,
                    isOfficial: item.status == '1' ? true : false
                }) :
                getStatusDeviceClient({
                    device_key: item?.device_key,
                    platform: item?.platform
                })
        )
        if (response.type.includes('fulfilled')) {
            isFarming = response?.payload?.data?.isFarming

            if (response?.payload?.ready && response?.payload?.data?.number !== null) {
                setLogin(true)
            }

            if (response?.payload?.result) {
                if (response?.payload?.ready) {
                    statusRaw = 'Connected'
                } else {
                    statusRaw = 'Disconnected'
                }
            } else {
                statusRaw = "Disconnected"
            }

            if (response.payload.data?.verified_name) {
                setWabaStatus(response.payload.data);
            }
        } else {
            statusRaw = 'Disconnected'
        }

        await dispatch(addStatusDevice({
            accountid: item.device_key,
            status: statusRaw,
            isFarming: isFarming,
        }))
    }

    useEffect(() => {
        loadGetStatusDevice()
    }, [])

    useEffect(() => {
        socket.on("connection-update", (res) => {
            if (res.id === deviceKey && res.status === "Connected") {
                setQrModal(false);
                setQrKey("loading");
                setStateDeviceKey(null);
            }
            if (res.id === item.device_key) {
                dispatch(
                    updateStatusDevice({
                        accountid: res.id,
                        status: res.status,
                    })
                )
            }
        });
    }, [deviceKey, item])

    useEffect(() => {
        const data = statusDevices.find(
            (e) => e.accountid === item.device_key
        );
        if (data) {
            setStatus(data.status);
            setIsFarming(data.isFarming);
        }
    }, [statusDevices, item])

    // whatsapp functionality
    async function handleLogoutDevice() {
        toast.loading("Please wait...");

        const data = {
            device_key: item.device_key,
        }

        await dispatch(logoutDevice(data)).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                toast.dismiss();
                toast.success(res.payload.message);

                const body = {
                    params: {
                        platform: item?.platform,
                    },
                };

                // dispatch(resetDevice())
                await dispatch(getDevices(body));

                await dispatch(
                    updateStatusDevice({
                        accountid: item.device_key,
                        status: "Logged Out",
                    })
                )
            } else {
                toast.dismiss();
                toast.error(res.payload.message);
            }
        })
    }

    const handleDeleteFarming = async () => {
        toast.loading("Please wait...");

        const data = {
            device_key: item.device_key,
        };

        await dispatch(deleteFarming(data)).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                toast.dismiss();
                toast.success(res.payload.message);

                setIsFarming(false);

                const body = {
                    params: {
                        platform: item?.platform,
                    },
                };
                const currentStatus = statusDevices.find(
                    (e) => e.accountid === item.device_key
                )
                dispatch(deleteStatusDevice({
                    accountid: item.device_key
                }))
                dispatch(addStatusDevice({
                    accountid: item.device_key,
                    status: currentStatus?.status,
                    isFarming: false,
                }))

                await dispatch(getDevices(body));
            } else {
                toast.dismiss();
                toast.error(res.payload.message);
            }
        });
    }

    const handleCreateFarming = async () => {
        toast.loading("Please wait...");

        const data = {
            device_key: item.device_key,
        };

        await dispatch(createFarming(data)).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                toast.dismiss();
                toast.success(res.payload.message);

                setIsFarming(true);

                const body = {
                    params: {
                        platform: item?.platform,
                    },
                };
                const currentStatus = statusDevices.find(
                    (e) => e.accountid === item.device_key
                )
                dispatch(deleteStatusDevice({
                    accountid: item.device_key
                }))
                dispatch(addStatusDevice({
                    accountid: item.device_key,
                    status: currentStatus?.status,
                    isFarming: true,
                }))

                await dispatch(getDevices(body));
            } else {
                toast.dismiss();
                toast.error(res.payload.message);
            }
        });
    }

    const handleDeleteDevice = async () => {
        toast.loading("Please wait...");

        const data = {
            device_key: item.device_key,
        };

        await dispatch(deleteDevice(data)).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                toast.dismiss();
                toast.success(res.payload.message);

                const body = {
                    params: {
                        platform: item?.platform,
                    },
                };

                dispatch(getDevices(body));
                dispatch(deleteStatusDevice({
                    accountid: data.device_key
                }))
            } else {
                toast.dismiss();
                toast.error(res.payload.message);
            }
        });
    }

    // cloud api functionality
    const handleUpdateCatalogSetting = async (
        typeAction,
        is_cart_enabled,
        is_catalog_visible
    ) => {
        toast.loading("Please wait...");

        let data = {
            device_key: item.device_key,
            is_cart_enabled,
            is_catalog_visible
        };

        if (typeAction === 'cart') {
            data.is_cart_enabled = !is_cart_enabled
        } else if (typeAction === 'catalog') {
            data.is_catalog_visible = !is_catalog_visible
        }

        await dispatch(updateCatalogSetting(data)).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                toast.dismiss();
                toast.success(res.payload.message);
                const body = {
                    params: {
                        platform: "waba",
                    },
                };
                dispatch(getDevices(body));
            } else {
                toast.dismiss();
                toast.error(res.payload.message);
            }
        });
    };

    const handleAddCatalogWaba = async (deviceKey) => {
        dispatch(toggleModalWaba(true));
        dispatch(setDeviceKey(deviceKey));
    }


    const handleAddCatalogInstagram = async (deviceKey) => {
        dispatch(toggleModalInstagram(true));
        dispatch(setDeviceKey(deviceKey));
    }

    // telegram functionality
    const handleUnlinkDevice = async (deviceKey) => {
        await toast.promise(
            dispatch(
                unlinkDevice({
                    device_key: deviceKey,
                })
            ),
            {
                loading: "Please wait...",
                success: (res) => {
                    const body = {
                        params: {
                            platform: "telegram",
                        },
                    };
                    dispatch(getDevices(body));
                    return res.payload.message;
                },
                error: (error) => "Error, try again later",
            }
        );
    };

    const handleAddTelegramLink = async (deviceKey) => {
        toast.loading("Please wait...");
        const body = {
            params: {
                platform: "whatsapp,waba",
            },
        };
        await dispatch(getListDevicesForCombobox(body))
            .unwrap()
            .then((result) => {
                dispatch(toggleModalTelegram(true));
                dispatch(setDeviceKey(deviceKey));
                toast.dismiss();
            })
            .catch((e) => {
                console.log("error", e);
                toast.dismiss();
            });
    };

    return (
        <>
            <Box sx={{
                display: { xs: 'none', sm: 'block', md: 'block' }
            }}>
                <DesktopMode
                    padding={paddingDesktop}
                    borderRadius={borderRadiusDesktop}
                    borderBottom={borderBottomDesktop}
                    icon={icon}
                    wabaStatus={wabaStatus}
                    item={item}
                    status={status}
                    platform={platform}
                    navigate={navigate}
                    isFarming={isFarming}
                    login={login}
                    handleLogoutDevice={handleLogoutDevice}
                    handleGetQrDevice={() => handleGetQrDevice(item?.device_key)}
                    setQrModal={setQrModal}
                    handleDeleteFarming={handleDeleteFarming}
                    handleCreateFarming={handleCreateFarming}
                    handleDeleteDevice={handleDeleteDevice}
                    handleUpdateCatalogSetting={(typeAction) => handleUpdateCatalogSetting(
                        typeAction,
                        item?.catalog_setting?.is_cart_enabled,
                        item?.catalog_setting?.is_catalog_visible
                    )}
                    handleAddCatalogWaba={() => handleAddCatalogWaba(item.device_key)}
                    handleAddCatalogInstagram={() => handleAddCatalogInstagram(item.device_key)}
                    handleUnlinkDevice={() => handleUnlinkDevice(item.device_key)}
                    handleAddTelegramLink={() => handleAddTelegramLink(item.device_key)}
                />
            </Box>
            <Box
                sx={{
                    display: { xs: 'block', sm: 'none', md: 'none' }
                }}
            >
                <MobileMode
                    paddingSummary={paddingSummary}
                    paddingDetails={paddingDetails}
                    padding={padding}
                    borderBottom={borderBottom}
                    borderRadius={borderRadius}
                    icon={icon}
                    wabaStatus={wabaStatus}
                    item={item}
                    status={status}
                    platform={platform}
                    navigate={navigate}
                    isFarming={isFarming}
                    login={login}
                    handleLogoutDevice={handleLogoutDevice}
                    handleGetQrDevice={() => handleGetQrDevice(item?.device_key)}
                    setQrModal={setQrModal}
                    handleDeleteFarming={handleDeleteFarming}
                    handleCreateFarming={handleCreateFarming}
                    handleDeleteDevice={handleDeleteDevice}
                    handleUpdateCatalogSetting={(typeAction) => handleUpdateCatalogSetting(
                        typeAction,
                        item?.catalog_setting?.is_cart_enabled,
                        item?.catalog_setting?.is_catalog_visible
                    )}
                    handleAddCatalogWaba={() => handleAddCatalogWaba(item.device_key)}
                    handleAddCatalogInstagram={() => handleAddCatalogInstagram(item.device_key)}
                    handleUnlinkDevice={() => handleUnlinkDevice(item.device_key)}
                    handleAddTelegramLink={() => handleAddTelegramLink(item.device_key)}
                />
            </Box>
        </>
    )
}