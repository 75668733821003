import { useDispatch } from "react-redux";
import ContainerWithSomeButton from "../../../../../components/containers/ContainerWithSomeButton";
import Connect from "../../Connect";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { addDevice } from "../../../../../store/device/deviceAction";
import { resetDevice } from "../../../../../store/device/deviceSlice";
import toast from "react-hot-toast";
import { Box, Typography } from "@mui/material";
import BasicButton from "../../../../../components/button/BasicButton";

export default function ConnectFacebook() {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [typeToken, setTypeToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");

        // Facebook Login with JavaScript SDK

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "342618604307492",
                autoLogAppEvents: false,
                xfbml: true,
                cookie: false,
                status: false,
                version: "v20.0",
            });
        };
    }, []);

    
    const lists = [
        'Admin access for Facebook Business Manager',
        'Admin access for Facebook Page',
        'Verified Facebook Business Manager Account'
    ]

    function clickBack() {
        navigate('/dashboard/channels')
    }

    const formik = useFormik({
        initialValues: {
            platform: 'facebook',
        },
        onSubmit: async (value, { resetForm }) => {
            setLoading(true)

            let data = null

            if (typeToken === 'code') {
                data = {
                    platform: value.platform,
                    code: accessToken ?? null
                }
            }

            dispatch(addDevice(data)).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();
                    setAccessToken(null);
                    setTypeToken(null);
                    dispatch(resetDevice())

                    navigate('/dashboard/channels') // Return to dashboard channels on success
                } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                }

                setLoading(false)
            });
        }
    })

    function launchFacebookSignup() {
        // Launch Facebook login
        //console.log("FB", window.FB);
        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    // const accessToken = response.authResponse.code;
                    // Use this token to call the debug_token API and get the shared WABA's ID
                    if (response.authResponse) {
                        // console.log("response", response);
                        // console.log("response", response.authResponse?.accessToken);
                        if (response?.authResponse?.code) {
                            //  console.log("accessToken", response?.authResponse?.code);
                            setAccessToken(response?.authResponse?.code);
                            setTypeToken("code");
                        }
                        // else if (response?.authResponse?.accessToken) {
                        //   console.log("accessToken", response?.authResponse?.accessToken);
                        //   setAccessToken(response?.authResponse?.accessToken);
                        //   setTypeToken("accessToken");
                        // }
                    } else {
                        toast.error("User cancelled login or did not fully authorize.");
                    }
                } else {
                    //  console.log("User cancelled login or did not fully authorize.");
                }
            },
            {
                config_id: "434926669252886", // configuration ID obtained in the previous step goes here
                response_type: "code", // must be set to 'code' for System User access token
                override_default_response_type: true,
                display: "popup",
                extras: {
                    setup: {},
                },
            }
        );
    }

    useEffect(() => {
        if (accessToken) {
            formik.handleSubmit()
        }
    }, [accessToken])

    useEffect(() => {
        window?.FB?.logout(function (response) {
            console.log("FB logout:", response);
        });
    }, [])

    return (
        <ContainerWithSomeButton>
            <Connect
                icon="facebook.png"
                title="Facebook"
                isBackBtn={true}
                clickBack={clickBack}
            />

            <Box
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    },
                    marginRight: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    }
                }}
                mb={4}
            >
                <Typography
                    fontSize={16}
                    color={'#7B7B7B'}
                    fontWeight={600}
                    mt={4}
                    mb={3}
                >
                    What will you need:
                </Typography>

                {lists.map((item, i) => (
                    <Box
                        key={i}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        mb={1}
                    >
                        <Box
                            sx={{
                                height: 7,
                                width: 7,
                                borderRadius: 500,
                                background: '#64D88B'
                            }}
                            mr={1}
                        />
                        <Typography
                            fontWeight={400}
                            fontSize={14}
                            color={'#7B7B7B'}
                            textAlign="start"
                        >
                            {item}
                        </Typography>
                    </Box>
                ))}

                <Typography
                    fontSize={16}
                    fontWeight={600}
                    my={4}
                >
                    How to connect?
                </Typography>

                <BasicButton
                    title="Login With Facebook"
                    disabled={loading}
                    onClick={launchFacebookSignup}
                />
            </Box>
        </ContainerWithSomeButton>
    )
}