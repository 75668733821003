import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import ContactList from "../sections/dashboard/contacts/contact-list";
import ContactScrapping from "../sections/dashboard/contacts/contact-scrapping";

const items = [
  {
    name: "Contact List",
    component: <ContactList />,
  },
  // {
  //   name: "Contact Scrapping",
  //   component: <ContactScrapping />,
  // },
];

export default function Contact() {
  const [contactPages, setContactPages] = useState(items[0]);

  return (
    <Page title="Contact">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Contact" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <Stack direction="row" spacing={1}>
              {items.map((v, i) => {
                // if (i === 2) {
                return (
                  <Button
                    variant={
                      contactPages.name === v.name ? "contained" : "text"
                    }
                    sx={
                      contactPages?.name === v.name
                        ? {
                            background:
                              "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                        : { color: "#7b7b7b" }
                    }
                    key={i}
                    onClick={() => setContactPages(v)}
                  >
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                      {" "}
                      {v.name}
                    </Typography>
                  </Button>
                );
                // } else {
                //   return (
                //     <Button
                //       variant={
                //         contactPages.name === v.name ? "contained" : "text"
                //       }
                //       key={i}
                //       onClick={() => {
                //         toast("Coming Soon", {
                //           type: "warning",
                //           isLoading: false,
                //           position: "top-right",
                //           autoClose: 5000,
                //           closeOnClick: true,
                //           draggable: true,
                //         });
                //         // setContactPages(v);
                //       }}
                //       sx={
                //         contactPages?.name === v.name
                //           ? {
                //               background:
                //                 "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                //               borderRadius: "24px",
                //               color: "#fdfdfd",
                //             }
                //           : { color: "#7b7b7b" }
                //       }
                //     >
                //       <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                //         {" "}
                //         {v.name}
                //       </Typography>
                //     </Button>
                //   );
                // }
              })}
            </Stack>
          </Box>
          <Box mt={3}>{contactPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
