import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import ScrollBar from "../components/Scrollbar";
import Profile from "../sections/dashboard/account/profile";
import AppDropDown from "../sections/dashboard/app/AppDropdown";

export default function Account() {
  const [accountPages, setAccountPages] = React.useState({
    name: "Profile",
    component: <Profile />,
  });

  const items = [
    {
      name: "Profile",
      component: <Profile />,
    },
  ];

  return (
    <Page title="Integration">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Account" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {items.map((v, i) => {
                  return (
                    <Button
                      variant={
                        accountPages?.name === v.name ? "contained" : "text"
                      }
                      key={i}
                      onClick={() => setAccountPages(v)}
                      sx={
                        accountPages?.name === v.name
                          ? {
                              background:
                                "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                              borderRadius: "24px",
                              color: "#fdfdfd",
                            }
                          : { color: "#7b7b7b" }
                      }
                    >
                      <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                        {v.name}
                      </Typography>
                    </Button>
                  );
                })}
              </Stack>
            </ScrollBar>
          </Box>
          <Box mt={3}>{accountPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
