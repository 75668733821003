import {
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import Iconify from "../../components/Iconify";
import BasicButton from "../../components/button/BasicButton";
import BasicInput from "../../components/input/BasicInput";
import InputPassword from "../../components/input/InputPassword";
import CustomModal from "../../components/modal/CustomModal";
import {
  authLogin,
  generateQr,
  verifyRegister,
} from "../../store/user/userAction";
import palette from "../../theme/palette";

export default function SignInSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenForgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState(false);

  const [isWhatsappMethod, setIsWhatsappMethod] = useState(false);
  const [loginQrsKey, setloginQrsKey] = useState(null);

  const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false);
  const [verifyQrKey, setverifyQrKey] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      toast.loading("Please wait...");

      const data = {
        email: value.email,
        password: value.password,
      };

      await dispatch(authLogin(data)).then((res) => {
        if (res.payload.data?.redirect) {
          setverifyQrKey(res.payload?.data);
          setIsOpenVerifyModal(true);
        }

        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
    },
  });

  const handleGenerateQr = async () => {
    await dispatch(generateQr()).then((res) => {
      const isMobile = window.innerWidth <= 768;

      if (isMobile) {
        setloginQrsKey(res.payload?.data);
        window.open(res.payload?.data?.redirect);
      } else {
        setloginQrsKey(res.payload?.data);
      }
    });

    setIsLoading(false);
  };

  const handleVerifyRegister = async ({ type }) => {
    const body = {
      token: type === "login" ? loginQrsKey?.token : verifyQrKey?.token,
      type: type,
    };

    await dispatch(verifyRegister({ data: body })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        navigate("/");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (loginQrsKey || verifyQrKey) {
        if (loginQrsKey) {
          handleVerifyRegister({ type: "login" });
        } else {
          handleVerifyRegister({ type: "register" });
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [loginQrsKey, verifyQrKey]);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (isWhatsappMethod) {
      handleGenerateQr();
    }
  }, [isWhatsappMethod]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: "16px", md: "24px" },
          width: "100%",
          maxWidth: "480px",
          background: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ margin: "0 0 8px 0" }}>
              <Box
                component="img"
                src="/images/komers.svg"
                sx={{ height: "48px", width: "150px" }}
              />
            </Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "700", color: "#131313", textAlign: "center" }}
            >
              Login to KOMERS
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: "16px",
                fontWeight: "400",
                color: "#7B7B7B",
                textAlign: "center",
              }}
            >
              Connecting Business, One Chat at a Time.
            </Typography>
            {!isWhatsappMethod ? (
              <>
                <Box sx={{ width: "100%" }}>
                  <BasicInput
                    type="text"
                    title="Email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.values?.email?.length ? (
                    <InputPassword
                      disableMargin
                      {...formik.getFieldProps("password")}
                    />
                  ) : null}
                </Box>
                <Box
                  sx={{
                    mb: "24px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      fontWeight: "700",
                      color: "#1A776F",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      const isMobile = window.innerWidth <= 768;

                      if (isMobile) {
                        window.location.href =
                          "https://wa.me/message/H3VKZ5XMWHMBA1";
                      } else {
                        setIsOpenForgotPasswordModal(true);
                      }
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
                <Box sx={{ my: "4px" }} />
                <BasicButton type="submit" disabled={isLoading} fullWidth>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "700", color: "#FFFFFF" }}
                  >
                    Login
                  </Typography>
                </BasicButton>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    mx: "auto",
                    height: "auto",
                    maxWidth: "160px",
                    width: "100%",
                  }}
                >
                  {loginQrsKey ? (
                    <>
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={loginQrsKey?.redirect}
                        viewBox={`0 0 256 256`}
                      />
                    </>
                  ) : (
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  )}
                </Box>
              </>
            )}

            <Divider sx={{ my: 2, width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400",
                  color: "#7B7B7B",
                  textAlign: "center",
                }}
              >
                Or
              </Typography>
            </Divider>
            <Box>
              {isWhatsappMethod ? (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={
                    <Iconify
                      icon="material-symbols:mail-outline"
                      width={24}
                      height={24}
                    />
                  }
                  sx={{
                    background: palette.gradients.custom,
                    borderRadius: "12px",
                    fontSize: "16px",
                    color: "#fdfdfd",
                  }}
                  onClick={() => {
                    setIsWhatsappMethod(!isWhatsappMethod);
                  }}
                >
                  Email
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={
                    <Iconify
                      icon="ic:baseline-whatsapp"
                      width={24}
                      height={24}
                    />
                  }
                  sx={{
                    background: palette.gradients.custom,
                    borderRadius: "12px",
                    fontSize: "16px",
                    color: "#fdfdfd",
                  }}
                  onClick={() => {
                    setIsWhatsappMethod(!isWhatsappMethod);
                  }}
                >
                  WhatsApp
                </Button>
              )}
            </Box>
            <Box sx={{ my: "32px" }} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "400", color: "#131313", textAlign: "center" }}
            >
              No account?{" "}
              <Link
                to="/auth/signup"
                style={{ fontWeight: "700", color: "#1A776F", textDecoration: 'none' }}
              >
                Sign up FREE
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>

      {/* https://wa.me/message/H3VKZ5XMWHMBA1 */}

      <CustomModal open={isOpenForgotPasswordModal}>
        <Box
          sx={{
            // ml: { xs: "0", md: "-120px" },
            ml: { xs: "0", md: "auto" },
            p: { xs: 3, md: 5 },
            // width: { xs: "100%", md: "720px" },
            width: { xs: "100%", md: "auto" },
            background: "#FFF",
            borderRadius: 2,
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography fontSize={24} fontWeight={600} color="#131313">
              Reset Password
            </Typography>
            <Button
              variant="text"
              sx={{ p: 0, minWidth: "36px", cursor: "pointer" }}
              onClick={() =>
                setIsOpenForgotPasswordModal(!isOpenForgotPasswordModal)
              }
            >
              <Iconify icon={"bi:x"} color="#7B7B7B" width={36} height={36} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                1. Open Camera on your phone.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                2. Scan the QR Code.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                3. Open the link in your browser.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                4. Open your Whatsapp and send the message.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                5. Enter your new password
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                6. Confirm your new password
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                7. Password changed successfully
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: "2px",
                  mx: "auto",
                  height: "auto",
                  maxWidth: "160px",
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value="https://wa.me/message/H3VKZ5XMWHMBA1"
                  viewBox={`0 0 256 256`}
                />
              </Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ color: palette.primary.main, textAlign: "center" }}
              >
                Reset Password with Qr Code
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal open={isOpenVerifyModal}>
        <Box
          sx={{
            // ml: { xs: "0", md: "-120px" },
            ml: { xs: "0", md: "auto" },
            p: { xs: 3, md: 5 },
            // width: { xs: "100%", md: "720px" },
            width: { xs: "100%", md: "auto" },
            background: "#FFF",
            borderRadius: 2,
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography fontSize={24} fontWeight={600} color="#131313">
              Verify Account
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                1. Open Camera on your phone.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                2. Scan the QR Code.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                3. Open the link in your browser.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                4. Open your Whatsapp and send the message.
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: "2px",
                  mx: "auto",
                  height: "auto",
                  maxWidth: "160px",
                  width: "100%",
                }}
              >
                {verifyQrKey ? (
                  <>
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={verifyQrKey?.redirect}
                      viewBox={`0 0 256 256`}
                    />
                  </>
                ) : (
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                )}
              </Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ color: palette.primary.main, textAlign: "center" }}
              >
                Verify with Qr Code
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
