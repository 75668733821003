import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeTable from "react-timetable-events";
import Container from "../../../components/containers/Container";
import { getUserActivity } from "../../../store/statistic/statisticAction";

export default function AppMostActiveUser({ ...other }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { userActivity } = useSelector((state) => state.statistic);

  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    if (userActivity) {
      const userActivityRaw = [];

      Object.entries(userActivity?.reply ?? {}).map((x) => {
        let timeRaw = [];

        Object.entries(x[1] ?? {}).map((item, index) => {
          timeRaw.push({
            id: index + 1,
            name: `highlight-${
              item[1] === 0
                ? "0"
                : item[1] < 25
                ? "25"
                : item[1] < 50
                ? "50"
                : item[1] < 75
                ? "75"
                : "100"
            }`,
            type: "custom",
            startTime: new Date(`2023-01-01T${item[0]}:00`),
            endTime: new Date(
              `2023-01-01T${
                Object.entries(x[1] ?? {}).length > index + 1
                  ? Object.entries(x[1] ?? {})[index + 1][0]
                  : Object.entries(x[1] ?? {})[index][0]
              }:00`
            ),
          });
        });

        userActivityRaw.push({ day: x[0], data: timeRaw });

        timeRaw = [];
      });

      setWeeks(userActivityRaw);
    }
  }, [userActivity]);

  const handleGetUserActivity = async (data) => {
    await dispatch(getUserActivity(data));
  };

  const renderHour = ({ hour, defaulAttributes }) => {
    return (
      <div
        {...defaulAttributes}
        key={hour}
        style={{
          padding: "8px 16px",
          fontSize: "12px",
          color: "#34495e",
          textAlign: "center",
          background: "#FDFDFD",
        }}
      >
        {hour}
      </div>
    );
  };

  const renderDayHeader = ({ day, defaulAttributes }) => {
    return (
      <div
        {...defaulAttributes}
        key={day}
        style={{
          padding: "8px 16px",
          fontSize: "12px",
          color: "#34495e",
          textAlign: "center",
          background: "#FDFDFD",
        }}
      >
        {day}
      </div>
    );
  };

  const renderEvent = ({ event, defaultAttributes }) => {
    return (
      <div
        {...defaultAttributes}
        title={event.name}
        key={event.id}
        style={{
          ...defaultAttributes.style,
        }}
      >
        <span></span>
      </div>
    );
  };

  useEffect(() => {
    if (user) {
      const data = {
          userId: user.id,
      };

      handleGetUserActivity(data);
    }
  }, [user]);
  
  return (
    <Container title="Most Active User" {...other}>
      <Box sx={{ pb: 0 }}>
        <TimeTable
          events={{
            monday: weeks?.find((e) => e.day === "monday")?.data,
            tuesday: weeks?.find((e) => e.day === "tuesday")?.data,
            wednesday: weeks?.find((e) => e.day === "wednesday")?.data,
            thursday: weeks?.find((e) => e.day === "thursday")?.data,
            friday: weeks?.find((e) => e.day === "friday")?.data,
            saturday: weeks?.find((e) => e.day === "saturday")?.data,
            sunday: weeks?.find((e) => e.day === "sunday")?.data,
          }}
          timeLabel=""
          hoursInterval={{ from: 0, to: 24 }}
          headerAttributes={{ style: { background: "#FDFDFD" } }}
          bodyAttributes={{ style: { background: "rgba(18, 205, 177, 0.15)" } }}
          renderHour={(data) => renderHour(data)}
          renderDayHeader={(data) => renderDayHeader(data)}
          renderEvent={(data) => renderEvent(data)}
        />
      </Box>
    </Container>
  );
}
