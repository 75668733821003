import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import ScrollBar from "../components/Scrollbar";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import AutoReply from "../sections/dashboard/marketing/auto-reply";
import AutoReplyWaba from "../sections/dashboard/marketing/auto-reply-waba";
import AutoReplyTelegram from "../sections/dashboard/marketing/auto-reply-telegram";
import BulkEmail from "../sections/dashboard/marketing/bulk-email";
import BulkMessage from "../sections/dashboard/marketing/bulk-message";
import BulkWaba from "../sections/dashboard/marketing/bulk-waba";
import GreetingMessage from "../sections/dashboard/marketing/greeting-message";
import ScheduleMessage from "../sections/dashboard/marketing/schedule-message";
import SendMessage from "../sections/dashboard/marketing/send-message";
import PlatformOptions from "../components/PlatformOptions";
import AutoReplyInstagram from "../sections/dashboard/marketing/auto-reply-instagram";
import AutoReplyFacebook from "../sections/dashboard/marketing/auto-reply-facebook";
const items = [
  {
    name: "WhatsApp Message",
    component: <SendMessage />,
  },
  {
    name: "WhatsApp Broadcast",
    component: <BulkMessage />,
  },
  {
    name: "WhatsApp Schedule",
    component: <ScheduleMessage />,
  },
  {
    name: "WhatsApp Auto Reply",
    component: <AutoReply />,
  },
  {
    name: "Greeting Message",
    component: <GreetingMessage />,
  },
];

const wabaItems = [
  {
    name: "Cloud API Broadcast",
    component: <BulkWaba />,
  },
  {
    name: "Cloud API Auto Reply",
    component: <AutoReplyWaba />,
  },
];

const emailItems = [
  {
    name: "Email Broadcast",
    component: <BulkEmail />,
  },
];

const telegramItems = [
  {
    name: "Telegram Auto Reply",
    component: <AutoReplyTelegram />,
  },
];

const instagramItems = [
  {
    name: "Instagram Auto Reply",
    component: <AutoReplyInstagram />,
  },
];

const facebookItems = [
  {
    name: "Facebook Auto Reply",
    component: <AutoReplyFacebook />,
  },
];

const platformOptions = [
  {
    label: "WhatsApp",
    value: "whatsapp",
    image: "whatsapp.png",
  },
  {
    label: "Cloud API",
    value: "waba",
    image: "cloud-api.png",
  },
  {
    label: "Email",
    value: "email",
    image: "email.png",
  },
  {
    label: "Telegram",
    value: "telegram",
    image: "telegram.png",
  },
  {
    label: "Instagram",
    value: "instagram",
    image: "instagram.png",
  },
  {
    label: "Facebook",
    value: "facebook",
    image: "facebook.png",
  },
];

export default function Marketing() {
  const [marketingPages, setMarketingPages] = React.useState({
    name: "Send Message",
    component: <SendMessage />,
  });

  const [platformSelected, setPlatformSelected] = useState(platformOptions[0]);

  useEffect(() => {
    if (platformSelected?.value === "whatsapp") {
      setMarketingPages(items[0]);
    } else if (platformSelected?.value === "waba") {
      setMarketingPages(wabaItems[0]);
    } else if (platformSelected?.value === "email") {
      setMarketingPages(emailItems[0]);
    } else if (platformSelected?.value === "telegram") {
      setMarketingPages(telegramItems[0]);
    } else if (platformSelected?.value === "instagram") {
      setMarketingPages(instagramItems[0]);
    } else if (platformSelected?.value === "facebook") {
      setMarketingPages(facebookItems[0]);
    } else {
      setMarketingPages({
        name: "",
        component: <></>,
      });
    }
  }, [platformSelected]);

  return (
    <Page title="Marketing">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Marketing" />
        <PlatformOptions
          platformOptions={platformOptions}
          platformSelected={platformSelected}
          setPlatformSelected={setPlatformSelected}
        />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {(platformSelected?.value === "whatsapp"
                  ? items
                  : platformSelected?.value === "waba"
                    ? wabaItems
                    : platformSelected?.value === "email"
                      ? emailItems
                      : platformSelected?.value === "telegram"
                        ? telegramItems
                        : platformSelected?.value === "instagram"
                          ? instagramItems
                          : platformSelected?.value === "facebook"
                            ? facebookItems
                      : []
                ).map((v, i) => {
                  return (
                    <Button
                      variant={
                        marketingPages?.name === v.name ? "contained" : "text"
                      }
                      key={i}
                      onClick={() => setMarketingPages(v)}
                      sx={
                        marketingPages?.name === v.name
                          ? {
                            background:
                              "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                          : { color: "#7b7b7b" }
                      }
                    >
                      <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                        {" "}
                        {v.name}
                      </Typography>
                    </Button>
                  );
                })}
              </Stack>
            </ScrollBar>
          </Box>
          <Box mt={3}>{marketingPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
