import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: "#A6C1BF",
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, index }) {
  const isActiveRoot = active(item.path);
  const { isHoverSidebar, isOpenSidebar } = useSelector(
    (state) => state.template
  );
  const [isShow, setIsShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { title, path, icon, info, children, url } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "#7B7B7B",
    fontSize: "16px",
    fontWeight: "fontWeightMedium",
    background:
      "linear-gradient(173deg, rgba(147,223,147,1) 0%, rgba(33,194,193,1) 100%)",
  };

  const activeSubStyle = {
    color: "#7B7B7B",
    fontSize: "16px",
    fontWeight: "fontWeightMedium",
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1200);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  if (url) {
    return (
      <ListItemStyle
        onClick={() => {
          window.open(path, "_blank");
        }}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </ListItemStyle>
    );
  } else {
    return (
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
        onMouseEnter={() => setIsShow(true)}
        onMouseLeave={() => setIsShow(false)}
        key={index}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isMobile ? (
            <ListItemText
              sx={{
                display: isHoverSidebar || isOpenSidebar ? "block" : "none",
                color: "#7B7B7B",
              }}
              disableTypography
              primary={title}
            />
          ) : (
            <>
              {isShow ? (
                <Box sx={{ left: "64px", position: "fixed" }}>
                  <ListItemText
                    sx={{
                      padding: "12px 16px",
                      color: "#7B7B7B",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      boxShadow:
                        "0px 3px 1px -2px rgba(145, 158, 171, 0.2), 0px 2px 2px 0px rgba(145, 158, 171, 0.14), 0px 1px 5px 0px rgba(145, 158, 171, 0.12)",
                    }}
                    disableTypography
                    primary={title}
                  />
                </Box>
              ) : (
                <Box></Box>
              )}
            </>
          )}
          {info && info}
        </Box>
      </ListItemStyle>
    );
  }
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item, index) => {
          return (
            <NavItem
              key={item.title}
              item={item}
              active={match}
              index={index}
            />
          );
        })}
      </List>
    </Box>
  );
}
